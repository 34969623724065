import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="About" />
    <h1>About</h1>
    <p>
      Mi chiamo Mattia Serchione, sono un Producer/Audio Engineer e chitarrista.
    </p>
    <p>
      Ho iniziato la mia avventura con la musica all’età di 10 anni quando ho iniziato a suonare la chitarra. Nel corso degli anni è sempre di più maturata la volontà di trasformare la mia passione per la musica in un lavoro.
    </p>
    <p>
      Nel 2014 ho iniziato il mio percorso di studi presso SAE Institute Milano, conseguendo inizialmente nel Gennaio 2017 il SAE Diploma in Audio Engineering e nel Maggio 2017 il Bachelor of Arts in Audio Production. Durante i 3 anni in SAE ho avuto modo di studiare e vivere ogni fase di una produzione musicale, dalle prime demo fino alla promozione e alla distribuzione.
    </p>
    <p>
      Nel corso degli anni ho collaborato con diversi progetti, non soffermandomi su un unico genere musicale ma spaziando su diverse produzioni dal pop al rap, dal rock al pop punk.
    </p>
  </Layout>
)

export default About
